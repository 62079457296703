<style>
.custom-warning {
}
.custom-warning .theme--dark.v-icon {
  color: red !important;
}
.custom-warning .wrn {
  background-color: white !important;
  color: red;
  border-color: var(--v-warning-base) !important;
  border-width: 2px;
  border-style: solid;
}
</style>
<template>
  <v-container
    class="payment-type payment-type-redirect payment-type-monetaweb"
  >
    <div
      class="payment-type-title"
      v-html="paymentType.title"
      v-if="paymentType.title"
    ></div>
    <div
      style="color: var(--v-primary-base);"
      class="payment-type-description"
      v-html="paymentType.descr"
      v-if="mode != 'addAuth'"
    ></div>

    <div
      v-else
      class="payment-type-description payment-type-description-add-auth"
      v-html="
        $t(`paymentTypeList.addNewPayemntAuth.${paymentType.paymentTypeId}`)
      "
    ></div>

    <div class="custom-warning">
      <v-alert type="warning" title="ATTENZIONE" class="wrn">
        ATTENZIONE<br />
        Per ordini superiori a <strong>{{ $n(75, "currency") }}</strong
        >, all’importo totale dell’ordine saranno aggiunti
        <strong>{{ $n(2, "currency") }}</strong> di marca da bollo
      </v-alert>
    </div>

    <div v-if="options.orderAmount" class="mt-3 mb-1">
      <div
        v-html="
          `${$t('paymentTypeList.orderAmount.text')}
      <strong>${$n(options.orderAmount, 'currency')}</strong>`
        "
      />
    </div>
    <ResponseMessage :response="response" />
    <v-row class="d-flex justify-end">
      <v-col
        cols="12"
        md="6"
        class="d-flex align-center"
        v-if="mode != 'order' && !options.hidePrevButton"
      >
      </v-col>

      <v-col cols="12" md="6" class="d-flex justify-end">
        <v-btn
          v-if="options.confirm.show"
          :color="options.confirm.color"
          :outlined="options.confirm.outline"
          class="mt-3 mt-sm-0 ml-2 gateway-btn"
          v-on:click="buy"
          :depressed="options.confirm.depressed"
          :large="options.confirm.large"
          :class="options.confirm.class"
        >
          {{ options.confirm.label }}
        </v-btn>
      </v-col>
    </v-row>
    <i18n
      v-if="$te(`paymentTypeList.${paymentType.gatewayClient}.caption`)"
      :path="`paymentTypeList.${paymentType.gatewayClient}.caption`"
      tag="div"
      class="caption"
    />
  </v-container>
</template>
<script>
import ResponseMessage from "@/components/ResponseMessage.vue";

import gateway from "~/mixins/gateway";

import OrderService from "~/service/orderService";
import SmartcartService from "~/service/smartcartService";

import {
  paymentMelaregalo,
  handlePayResponseKoMelaregalo
} from "./gatewayUtilsService";
import { mapActions } from "vuex";

export default {
  name: "PaymentTypeMonetawebMelaregalo",
  mixins: [gateway],
  props: {
    smartCart: { type: Object, required: false }
  },

  data() {
    return {
      loading: false,
      error: null,
      selectedPaymentAuthId: null,
      rememberNewCard: this.options.rememberCardDefaultValue || false,
      response: {},
      cardList: []
    };
  },
  components: { ResponseMessage },
  methods: {
    ...mapActions({
      buyCart: "cart/buyCart",
      updateCurrentOrderId: "app/updateCurrentOrderId"
    }),

    actionPreBuy() {
      console.log("actionPreBuy");
      SmartcartService.resetProds(7);
      SmartcartService.addProductsToList(this.smartCart, 7);
    },

    async buy() {
      let vm = this;

      vm.loading = true;

      try {
        console.log(this.smartCart);
        // vm.actionPreBuy();
        let dataRaw = await SmartcartService.confirm(
          7,
          this.smartCart.warehouse.warehouseId,
          this.paymentType.paymentTypeId
        );
        console.log(dataRaw);
        //payResponse OK
        // this.$router.push("/cart/payment-completed-melaregalo");
        vm.payResponseOk(dataRaw.data);
      } catch (err) {
        vm.response = err;
      } finally {
        vm.loading = false;
      }
    },
    async addNewCard() {
      let vm = this;
      vm.loading = true;
      try {
        let paymentData = { remember_card: this.rememberNewCard };
        if (vm.mode == "cart") {
          let cart = await vm.buyCart({
            paymentTypeId: this.paymentType.paymentTypeId,
            paymentData: paymentData
          });
          if (cart) {
            //aggiungo il CURRENT_ORDER_ID al localStorage
            vm.updateCurrentOrderId(cart.order.orderId);
            vm.payResponseOk(cart);
          } else {
            this.$router.push("/payment-error");
          }
        } else if (vm.mode == "order") {
          let response = await OrderService.modifyOrderPaymentType(
            vm.orderId,
            vm.paymentType.paymentTypeId,
            paymentData
          );
          if (response) {
            //aggiungo il CURRENT_ORDER_ID al localStorage
            vm.updateCurrentOrderId(vm.orderId);
            vm.payResponseOk(response);
          } else {
            this.$router.push("/cart/payment-error-melaregalo");
          }
        }
      } catch (err) {
        vm.error = err.message;
      } finally {
        vm.loading = false;
      }
    },
    payResponseOk(cart) {
      let vm = this;
      let orderId = null;
      global.EventBus.$emit("pay-response-ok", cart);
      if (cart && cart.paymentUrl) {
        orderId = cart.order ? cart.order.orderId : cart.orderId;
        vm.doRedirect(cart.paymentUrl, cart.paymentParameters, "get", orderId);
        this.loading = false;
      } else {
        orderId = cart.order ? cart.order.orderId : cart.orderId;
        this.$router.push("/cart/payment-completed-melaregalo/" + orderId);
        this.loading = false;
      }
    },
    payResponseKo(data) {
      this.loading = false;
      handlePayResponseKoMelaregalo(data, this.orderId);
    },
    doRedirect(paymentUrl, paymentParameters, method, orderId) {
      paymentMelaregalo(paymentUrl, paymentParameters, method, orderId);
    },
    goBack() {
      this.$router.push("/checkout");
    },
    selectPaymentAuth(paymentAuthId) {
      this.selectedPaymentAuthId = paymentAuthId;
    },
    toggleRememberCard(value) {
      this.rememberNewCard = value;
    },
    calcRememberNewCard() {
      const rememberNewCard = global.EventBus.$t(
        "paymentTypeList.rememberNewCard"
      );
      if (typeof rememberNewCard === "string") {
        return rememberNewCard;
      } else {
        return this.cardList.length > 0
          ? rememberNewCard.new
          : rememberNewCard.first;
      }
    },
    paymentAuthList(arr) {
      this.cardList = arr;
    }
  }
};
</script>
